import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { observer } from 'mobx-react'
import Container from '../layout/Container'
import { useHistory } from 'react-router-dom'
import { InputFieldWithMoreOptions } from '../commonComponent/InputFieldComponent'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import { Props, Dictionary, ValueLabel } from '../../store/types.d'
import classNames from 'classnames'
import { handleKeyDownWithOutSpace, onChangeEmail } from '../commonComponent/WithoutAcceptSpace'
import Select from 'react-select'
import { paymentMethodsOptions, USER_ROLE_ORG_ADMIN } from '../../utils/constant'
import { toast } from 'react-toastify'


const AddUpdateOrganizationPage: React.FC<Props> = ({ rootStore }) => {
    const { register, handleSubmit, errors, control, setValue } = useForm()
    const { organizationStore, allUsersStore, setTitle, allOrgStore } = rootStore
    const { isApiError, isLoading, apiErrorMessage, organizationDetails, setOrganizationDetails, resetOrganizationDetails, addUpdateOrganizationDetails, resetError } = organizationStore
    const { organizationOwnerNamesWithValueLabel } = allUsersStore

    const history = useHistory()
    const pageName = (history.location.pathname.includes('/add')) ? 'Add Organization' : 'Organization Settings'
    const pathArrayWithStateId = history.location.pathname.split('/')

    const organizationId = (pageName === 'Organization Settings') ? pathArrayWithStateId[3] : ''

    const [lmIntegration, setLMIntegration] = useState(false)
    const [convertkitIntegration, setConvertkitIntegration] = useState(false)
    const [sendgridIntegration, setSendgridIntegration] = useState(false)
    const [xeroIntegration, setXeroIntegration] = useState(false)
    const [owner, setOwner] = useState<ValueLabel>()
    const [paymentMethods, setPaymentMethods] = useState<Array<ValueLabel>>()
    const [paymentMethodRequired, setPaymentMethodRequired] = useState(false)
    const [isOwnersListLoaded, setOwnersListLoaded] = useState(false)
    // const [logo, setLogo] = useState<string | ArrayBuffer | null>(null)
    // const [preview, setPreview] = useState<string | ArrayBuffer | null>(null)
    // const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        if (isOwnersListLoaded === false) {
            allUsersStore.forceReload()
            setOwnersListLoaded(true)
        }
        if (pageName === 'Organization Settings' && (organizationDetails._id === '' || organizationId !== organizationDetails._id)) {
            setOrganizationDetails(organizationId)
        } else if(pageName === 'Add Organization' && organizationDetails._id !== '' ) {
            resetOrganizationDetails()
            resetError()
        }
        if(pageName === 'Organization Settings' && organizationDetails._id !== '' && organizationId === organizationDetails._id && owner === undefined) {
            const owner = organizationOwnerNamesWithValueLabel.find((user) => user.value === organizationDetails.owner_id.toString())
            if (owner !== undefined) {
                setOwner(owner)
                setValue('owner_id', owner)
            }
            // if (organizationDetails.logo && organizationDetails.logo !== '') {
            //     setPreview(organizationDetails.logo)
            // }
            if (organizationDetails.payment_method_options !== undefined && organizationDetails.payment_method_options.length !== 0) {
                setPaymentMethods(organizationDetails.payment_method_options)
                setValue('payment_method_options', organizationDetails.payment_method_options)
            }
            setPaymentMethodRequired(organizationDetails.payment_method_required || false)
            setLMIntegration(organizationDetails.lawmatics_integration)
            setConvertkitIntegration(organizationDetails.convertkit_integration)
            setSendgridIntegration(organizationDetails.sendgrid_integration)
            setXeroIntegration(organizationDetails.xero_integration)
        }
    }, [pageName, organizationId, resetOrganizationDetails, setOrganizationDetails, organizationDetails, organizationOwnerNamesWithValueLabel, allUsersStore, setValue, resetError, isOwnersListLoaded, owner])

    useEffect(() => {
        if (pageName === 'Organization Settings') {
            if(organizationId === organizationDetails._id){
                setTitle(`${organizationDetails.name} | Manage Organization`)
            }
        } else if(pageName === 'Add Organization') {
            setTitle('Organization | Add')
        }
    })

    const handleClick = () => {
        resetOrganizationDetails()
        if (localStorage.getItem('userRole') !== USER_ROLE_ORG_ADMIN) {
            history.push('/organizations/')
        } else {
            history.push('/dashboard/')
        }
        
    }

    // const handleFileChange = (e: any) => {
    //     const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']
    //     const file = e.target.files[0]
    //     setError(null)
    //     console.log(file.type)
    //     if (file && file.type !== '' && allowedTypes.includes(file.type)) {
    //         const reader = new FileReader()
    //         reader.onloadend = () => {
    //             setLogo(reader.result as string)
    //             setPreview(reader.result as string)
    //         }
    //         reader.readAsDataURL(file)
    //     } else {
    //         setError('Only PNG, JPEG, and JPG files are allowed.')
    //     }
    // }

    // const removeFile = () => {
    //     setLogo(null)
    //     setPreview(null)
    // }
    
    const onSubmit = async (data: Dictionary<string>) => {

        // if (error !== null) {
        //     return
        // }
        const requestData = {
            _id: organizationId,
            name: data.name,
            about: data.about,
            owner_id: data.owner_id.value,
            // logo: logo,
            payment_method_options: data.payment_method_options,
            payment_method_required: paymentMethodRequired,
            lawmatics_integration: lmIntegration,
            lawmatics_configuration: {
                client_id: data.client_id,
                client_secret: data.client_secret,
                user_email: data.user_email,
                location_id: data.location_id
            },
            sendgrid_integration: sendgridIntegration,
            sendgrid_configuration: { 
                api_key: data.api_key,
                from_email: data.from_email,
                cc_email: data.cc_email
            },
            xero_integration: xeroIntegration,
            xero_configuration: {
                xero_client_secret: data.xero_client_secret,
                xero_client_id: data.xero_client_id,
                tenant_name: data.tenant_name,
                suspense_account_code: data.suspense_account_code,
                item_code: data.item_code,
                tracking_name: data.tracking_name,
                currency_code: data.currency_code,
                tax_type: data.tax_type,
                line_amount_type: data.line_amount_type,
                organization_code: data.organization_code
            },
            convertkit_integration: convertkitIntegration,
            convertkit_configuration: {
                api_key: data.api_key_c,
                api_secret: data.api_secret,
                form_id_trustee_info: data.form_id_trustee_info,
                form_id_trustee_reminder: data.form_id_trustee_reminder,
                tag_id: data.tag_id
            }
        }

        const response = await addUpdateOrganizationDetails(requestData)
        if (response.success === 1) {
            if (localStorage.getItem('userRole') !== USER_ROLE_ORG_ADMIN) {
                allOrgStore.forceReload()
                history.push('/organizations/')
            } else {
                history.push('/dashboard/')
            }
            setTimeout(() => {
                toast.success(response.message)
            }, 500)
        }
    }

    const handleChangeLMIntegration = (e: any) => {
        setLMIntegration(e.target.checked)
    }

    const handleChangeRequiredPaymentMethod = (e: any) => {
        setPaymentMethodRequired(e.target.checked)
    }

    const handleChangeConvertkitIntegration = (e: any) => {
        setConvertkitIntegration(e.target.checked)
    }

    const handleChangeXeroIntegration = (e: any) => {
        setXeroIntegration(e.target.checked)
    }

    const handleChangeSendgridIntegration = (e: any) => {
        setSendgridIntegration(e.target.checked)
    }

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <Breadcrumb links={[
            { name: 'Organizations', to: '/organizations/' },
            { name: (pageName === 'Organization Settings') ? 'Settings' : 'Add', isActive: true }
        ]} />

        <div className="card">
            <div className="card-header">
                {pageName}
            </div>
            <div className="card-body">
                <div className='row'>
                    <div className='col col col-md-8'>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' >
                            {apiErrorMessage}
                        </div>}

                        {(organizationId === organizationDetails._id)
                            ? <form onSubmit={handleSubmit(onSubmit)} >
                                <fieldset>

                                    <InputFieldWithMoreOptions
                                        label='Name'
                                        fieldName='name'
                                        register={register}
                                        errors={errors.name}
                                        placeholder='Add your organization name'
                                        defaultValue={organizationDetails.name}
                                        required={true}
                                    />

                                    <InputFieldWithMoreOptions
                                        label='About'
                                        fieldName='about'
                                        register={register}
                                        errors={errors.about}
                                        placeholder='Say something about organization'
                                        defaultValue={organizationDetails.about}
                                    />

                                    <div className='form-group' style={{zIndex:'11'}}>
                                        <label>Owner<span style={{color:'red'}}>*</span></label>
                                        <Controller
                                            control={control}
                                            name="owner_id"
                                            className='form-group'
                                            defaultValue={owner || null}
                                            
                                            rules={{
                                                validate: (value) => (value === undefined || value === null) ? 'Please select owner of the organization' : true
                                            }}
                                            render={({ onChange, ref, value }) => (
                                                <Select
                                                    inputRef={ref}
                                                    value={value}
                                                    isDisabled={pageName === 'Organization Settings'}
                                                    options={organizationOwnerNamesWithValueLabel}
                                                    className={(errors.owner_id) ? 'is-invalid' : ''}
                                                    classNamePrefix="select"
                                                    onChange={val => {
                                                        onChange(val)
                                                    }}
                                                    placeholder='Select the Owner'
                                                    isSearchable={true}
                                                />)}
                                            
                                        />
                                        {(errors.owner_id) && (<div className='invalid-feedback'>Please select owner of the organization</div>)}
                                    </div>

                                    {/* <div className="form-group">
                                        <label htmlFor="logo-upload">Logo</label>
                                        <input
                                            accept="image/*"
                                            type="file"
                                            id="logo-upload"
                                            className="form-control-file"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                        />
                                        <div className="border p-4 rounded" style={{ border: '2px dashed #ccc' }}>
                                            {preview ? (
                                                <>
                                                    <img
                                                        src={(typeof preview === 'string') ? preview : ''}
                                                        alt="logo preview"
                                                        style={{ width: '150px', height: '150px', objectFit: 'contain' }}
                                                        className="img-thumbnail mb-3"
                                                    />
                                                    <br />
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary mr-3"
                                                        onClick={removeFile}
                                                    >
                                                        <X />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary"
                                                    >
                                                        <label className='m-0 p-0' htmlFor="logo-upload">
                                                            <PencilFill />
                                                        </label>
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    
                                                    <label htmlFor="logo-upload" className="btn btn-primary">
                                                        Upload Logo
                                                    </label>
                                                    <small className="form-text text-muted mt-2">
                                                        Accepts .jpg, .png, and .jpeg only.
                                                    </small>
                                                </>
                                            )}
                                        </div>
                                        {error !== null && <div className="error-message">{error}</div>}
                                    </div> */}
                                    <div className='form-group' style={{zIndex:'10'}}>
                                        <label>Payment Method Options<span style={{color:'red'}}>*</span></label>
                                        <Controller
                                            control={control}
                                            name="payment_method_options"
                                            className='form-group'
                                            defaultValue={paymentMethods}
                                            rules={{
                                                validate: (value: Array<ValueLabel>) => (value === undefined || value === null || value.length === 0) ? 'Please select payment method options' : true
                                            }}
                                            render={({ onChange, ref, value }) => (
                                                <Select
                                                    inputRef={ref}
                                                    value={value}
                                                    options={paymentMethodsOptions}
                                                    className={(errors.payment_method_options) ? 'is-invalid' : ''}
                                                    classNamePrefix="select"
                                                    onChange={val => {
                                                        onChange(val)
                                                    }}
                                                    placeholder='Select the Payment Method Options'
                                                    isSearchable={true}
                                                    isMulti
                                                />
                                            )}
                                        />
                                        {(errors.payment_method_options) && (<div className='invalid-feedback'>Please select payment method options </div>)}
                                        <small className='form-text text-muted'>This payment method options which is shows as select from vision -{'>'} engagement screen</small>
                                    </div>
                                    <div className="custom-control form-group custom-switch" style={{marginBottom: '0px'}}>
                                        <input type="checkbox" defaultChecked={organizationDetails.payment_method_required}
                                            ref={register}
                                            className="custom-control-input" name='payment_method_required' id="paymentMethodRequired" onChange={handleChangeRequiredPaymentMethod} />
                                        <label className="custom-control-label" htmlFor='paymentMethodRequired'>Payment Method Required</label>
                                        {errors.payment_method_required && <div className='invalid-feedback'>{errors.payment_method_required.message}</div>}
                                    </div>
                                    <small style={{marginBottom: '1rem', marginTop: '0px'}} className='form-text text-muted'>In vision if the Payment Method input is mandatory.</small>
                                    <div className={classNames({'d-none': (localStorage.getItem('userRole') === USER_ROLE_ORG_ADMIN) })}>
                                        <div className="custom-control form-group custom-switch">
                                            <input type="checkbox" defaultChecked={organizationDetails.lawmatics_integration}
                                                ref={register}
                                                className="custom-control-input" name='lawmatics_integration' id="lawmaticsIntegration" onChange={handleChangeLMIntegration} />
                                            <label className="custom-control-label" htmlFor='lawmaticsIntegration'>Lawmatics Integration</label>
                                            {errors.lawmatics_integration && <div className='invalid-feedback'>{errors.lawmatics_integration.message}</div>}
                                        </div>

                                        <div className={classNames('card card-body', {'d-none': ( lmIntegration === false) })}>
                                            <InputFieldWithMoreOptions
                                                label='Client ID'
                                                fieldName='client_id'
                                                register={register}
                                                errors={errors.client_id}
                                                placeholder='Enter Client ID'
                                                defaultValue={organizationDetails.lawmatics_configuration.client_id}
                                                required={lmIntegration === true ? true : false}
                                                isSpaceAllowBetween={false}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Client Secret'
                                                fieldName='client_secret'
                                                register={register}
                                                errors={errors.client_secret}
                                                placeholder='Enter Client Secret'
                                                defaultValue={organizationDetails.lawmatics_configuration.client_secret}
                                                required={lmIntegration === true ? true : false}
                                                isSpaceAllowBetween={false}
                                            />
                                            <div className="form-group">
                                                <label>User Email<span style={{color:'red'}}>*</span></label>
                                                <input
                                                    ref={register({
                                                        required: lmIntegration ? 'This field is required' : false,
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: 'Invalid email address'
                                                        }
                                                    })}
                                                    name='user_email'
                                                    defaultValue={organizationDetails.lawmatics_configuration.user_email}
                                                    type='text'
                                                    onChange={onChangeEmail}
                                                    onKeyDown={handleKeyDownWithOutSpace}
                                                    className={classNames('form-control', { 'is-invalid': errors.user_email })}
                                                    placeholder='Enter User Email'
                                                />
                                                {errors.user_email && (<div className='invalid-feedback'>{errors.user_email.message}</div>)}
                                            </div>
                                            <InputFieldWithMoreOptions
                                                label='Location ID'
                                                fieldName='location_id'
                                                register={register}
                                                errors={errors.location_id}
                                                placeholder='Enter Location ID'
                                                defaultValue={organizationDetails.lawmatics_configuration.location_id}
                                                required={lmIntegration}
                                            />
                                        </div>

                                        <div className="custom-control mt-3 form-group custom-switch">
                                            <input type="checkbox" defaultChecked={organizationDetails.sendgrid_integration}
                                                ref={register}
                                                className="custom-control-input" name='sendgrid_integration' id="sendgridIntegrationSwitch" onChange={handleChangeSendgridIntegration} />
                                            <label className="custom-control-label" htmlFor='sendgridIntegrationSwitch'>Sendgrid Integration</label>
                                            {errors.sendgrid_integration && <div className='invalid-feedback'>{errors.sendgrid_integration.message}</div>}
                                        </div>
    
                                        <div className={classNames('card card-body', {'d-none': ( sendgridIntegration !== true) })}>
                                            <InputFieldWithMoreOptions
                                                label='API Key'
                                                fieldName='api_key'
                                                register={register}
                                                errors={errors.api_key}
                                                placeholder='Enter API Key'
                                                defaultValue={organizationDetails.sendgrid_configuration.api_key}
                                                required={(sendgridIntegration)}
                                            />

                                            <div className="form-group">
                                                <label>From Email<span style={{color:'red'}}>*</span></label>
                                                <input
                                                    ref={register({
                                                        required: sendgridIntegration ? 'This field is required' : false,
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: 'Invalid email address'
                                                        }
                                                    })}
                                                    name='from_email'
                                                    defaultValue={organizationDetails.sendgrid_configuration.from_email}
                                                    type='text'
                                                    onChange={onChangeEmail}
                                                    onKeyDown={handleKeyDownWithOutSpace}
                                                    className={classNames('form-control', { 'is-invalid': errors.from_email })}
                                                    placeholder='Enter email'
                                                />
                                                {errors.from_email && (<div className='invalid-feedback'>{errors.from_email.message}</div>)}
                                            </div>
                                            <div className="form-group">
                                                <label>Email Added in CC for WAA and CRD</label>
                                                <input
                                                    ref={register({
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: 'Invalid email address'
                                                        }
                                                    })}
                                                    name='cc_email'
                                                    defaultValue={organizationDetails.sendgrid_configuration.cc_email}
                                                    type='text'
                                                    onChange={onChangeEmail}
                                                    onKeyDown={handleKeyDownWithOutSpace}
                                                    className={classNames('form-control', { 'is-invalid': errors.cc_email })}
                                                    placeholder='Enter email'
                                                />
                                                {errors.cc_email && (<div className='invalid-feedback'>{errors.cc_email.message}</div>)}
                                            </div>
                                        </div>

                                        <div className="custom-control mt-3 form-group custom-switch">
                                            <input type="checkbox" defaultChecked={organizationDetails.xero_integration}
                                                ref={register}
                                                className="custom-control-input" name='xero_integration' id="xeroIntegrationSwitch" onChange={handleChangeXeroIntegration} />
                                            <label className="custom-control-label" htmlFor='xeroIntegrationSwitch'>Xero Integration</label>
                                            {errors.xero_integration && <div className='invalid-feedback'>{errors.xero_integration.message}</div>}
                                        </div>

                                        <div className={classNames('card card-body', {'d-none': ( xeroIntegration !== true) })}>
                                            <InputFieldWithMoreOptions
                                                label='Client ID'
                                                fieldName='xero_client_id'
                                                register={register}
                                                errors={errors.xero_client_id}
                                                placeholder='Enter Client ID'
                                                defaultValue={organizationDetails.xero_configuration.xero_client_id}
                                                required={xeroIntegration === true}
                                                isSpaceAllowBetween={false}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Client Secret'
                                                fieldName='xero_client_secret'
                                                register={register}
                                                errors={errors.xero_client_secret}
                                                placeholder='Enter Client Secret'
                                                defaultValue={organizationDetails.xero_configuration.xero_client_secret}
                                                required={xeroIntegration === true}
                                                isSpaceAllowBetween={false}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Tenant Name'
                                                fieldName='tenant_name'
                                                register={register}
                                                errors={errors.tenant_name}
                                                placeholder='Enter Tenant Name'
                                                defaultValue={organizationDetails.xero_configuration.tenant_name}
                                                // required={xeroIntegration}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Suspense Account Code'
                                                fieldName='suspense_account_code'
                                                register={register}
                                                errors={errors.suspense_account_code}
                                                placeholder='Enter Suspense Account Code'
                                                defaultValue={organizationDetails.xero_configuration.suspense_account_code}
                                                required={xeroIntegration}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Item Code'
                                                fieldName='item_code'
                                                register={register}
                                                errors={errors.item_code}
                                                placeholder='Enter Item Code'
                                                defaultValue={organizationDetails.xero_configuration.item_code}
                                                required={xeroIntegration}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Tracking Name'
                                                fieldName='tracking_name'
                                                register={register}
                                                errors={errors.tracking_name}
                                                placeholder='Enter Tracking Name'
                                                defaultValue={organizationDetails.xero_configuration.tracking_name}
                                                required={xeroIntegration}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Currency Code'
                                                fieldName='currency_code'
                                                register={register}
                                                errors={errors.currency_code}
                                                placeholder='Enter Currency Code'
                                                defaultValue={organizationDetails.xero_configuration.currency_code}
                                                required={xeroIntegration}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Tax Type'
                                                fieldName='tax_type'
                                                register={register}
                                                errors={errors.tax_type}
                                                placeholder='Enter Tax Type'
                                                defaultValue={organizationDetails.xero_configuration.tax_type}
                                                required={xeroIntegration}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Line Amount Type'
                                                fieldName='line_amount_type'
                                                register={register}
                                                errors={errors.line_amount_type}
                                                placeholder='Enter Line Amount Type'
                                                defaultValue={organizationDetails.xero_configuration.line_amount_type}
                                                required={xeroIntegration}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Organization Code'
                                                fieldName='organization_code'
                                                register={register}
                                                errors={errors.organization_code}
                                                placeholder='Enter Invoice Status'
                                                defaultValue={organizationDetails.xero_configuration.organization_code}
                                                required={xeroIntegration}
                                            />
                                        </div>

                                        <div className="custom-control mt-3 form-group custom-switch">
                                            <input type="checkbox" defaultChecked={organizationDetails.convertkit_integration}
                                                ref={register}
                                                className="custom-control-input" name='convertkit_integration' id="convertkitIntegrationSwitch" onChange={handleChangeConvertkitIntegration} />
                                            <label className="custom-control-label" htmlFor='convertkitIntegrationSwitch'>Convertkit Integration</label>
                                            {errors.convertkit_integration && <div className='invalid-feedback'>{errors.convertkit_integration.message}</div>}
                                        </div>

                                        <div className={classNames('card card-body', {'d-none': ( convertkitIntegration !== true) })}>
                                            <InputFieldWithMoreOptions
                                                label='API Key'
                                                fieldName='api_key_c'
                                                register={register}
                                                errors={errors.api_key_c}
                                                placeholder='Enter API Key'
                                                defaultValue={organizationDetails.convertkit_configuration.api_key}
                                                required={convertkitIntegration}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='API Secret'
                                                fieldName='api_secret'
                                                register={register}
                                                errors={errors.api_secret}
                                                placeholder='Enter API Secret'
                                                defaultValue={organizationDetails.convertkit_configuration.api_secret}
                                                required={convertkitIntegration}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Form ID For Trustee Info'
                                                fieldName='form_id_trustee_info'
                                                register={register}
                                                errors={errors.form_id_trustee_info}
                                                placeholder='Enter Form ID'
                                                defaultValue={organizationDetails.convertkit_configuration.form_id_trustee_info}
                                                required={convertkitIntegration}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Form ID For Trustee Reminder'
                                                fieldName='form_id_trustee_reminder'
                                                register={register}
                                                errors={errors.form_id_trustee_reminder}
                                                placeholder='Enter Form ID'
                                                defaultValue={organizationDetails.convertkit_configuration.form_id_trustee_reminder}
                                                required={convertkitIntegration}
                                            />
                                            <InputFieldWithMoreOptions
                                                label='Tag ID'
                                                fieldName='tag_id'
                                                register={register}
                                                errors={errors.tag_id}
                                                placeholder='Enter Tag ID'
                                                defaultValue={organizationDetails.convertkit_configuration.tag_id}
                                                required={convertkitIntegration}
                                            />
                                        </div>
                                    </div>

                                    <div className='form-group mt-4'>
                                        <button type='submit' className='btn btn-outline-primary' disabled={isLoading}>&nbsp;
                                            {isLoading ? 'Loading...' : 'Submit'}
                                        </button>&nbsp;&nbsp;
                                        <button type='button' className='btn btn-outline-danger' onClick={handleClick} disabled={isLoading}>Cancel</button>
                                    </div>
                                </fieldset>
                            </form>
                            : ''}
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(AddUpdateOrganizationPage)