export const initialClientDetailsForMatters = {
    gender: '',
    street: '',
    city: '',
    county: '',
    country: '',
    county_ref: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    _id: '',
    state: {
        code: '',
        name: ''
    },
    uid: '',
    type: '',
    is_client: 1,
    marital_status: '',
    children: [],
    life_partner: '',
    date_of_married: '',
    birthdate: '',
    state_id: '',
    phone_numbers: {
        home: '',
        work: '',
        mobile: ''
    },
    email: '',
    spouse_email: '',
    full_name: '',
    updatedAt: ''
}

export const initialTableDataWithPaginationDetails = {
    currentPage: 0,
    totalPage: 0,
    totalItem: 0,
    itemPerPage: 0,
    isFiltered: false,
    totalAllItems: 0,
    page_items: []
}

export const initialAnalyticsCronLog = {
    _id: '',
    matter_to_process: 0,
    matter_processed: 0,
    vision_to_process: 0,
    vision_processed: 0,
    deed_to_process: 0,
    deed_processed: 0,
    error: [],
    time: ''
}

export const initialProbableDetails = {
    _id: '',
    is_imported: 0,
    first_name: '',
    last_name: '',
    // full_name: '',
    middle_name: '',
    is_client: 0,
    added_by: '',
    category: 'Client',
    type: '',
    state: '',
    employee: '',
    gender: '',
    birthdate: '',
    street: '',
    city: '',
    source: '',
    county: '',
    country: '',
    county_ref: '',
    marital_status: '',
    email: '',
    // children: '',
    // date_of_married: '',
    // life_partner: '',
    // is_updated: false,
    createdAt: '',
    updatedAt: '',
    phone_numbers: {
        home: '',
        work: '',
        mobile: ''
    },
    spouse_first_name: '',
    spouse_last_name: '',
    // spouse_full_name: ''
    lawmatics_contact_id: '',
    lawmatics_matter_id: '',
    zephyr_probable_uid: '',
    user_associated_tags: []
}

export const initialOrganizationInformation = {
    _id: '',
    name: '',
    about: '',
    owner_id: '',
    // logo: '',
    payment_method_options: [],
    payment_method_required: false,
    is_active: false,
    lawmatics_integration: false,
    lawmatics_configuration: {
        client_id: '',
        client_secret: '',
        user_email: '',
        location_id: ''
    },
    sendgrid_integration: false,
    sendgrid_configuration: {
        api_key: '',
        from_email: '',
        cc_email: ''
    },
    xero_integration: false,
    xero_configuration: {
        xero_client_id: '',
        xero_client_secret: '',
        tenant_name: '',
        suspense_account_code: '',
        item_code: '',
        tracking_name: '',
        currency_code: '',
        tax_type: '',
        line_amount_type: '',
        organization_code: ''
    },
    convertkit_integration: false,
    convertkit_configuration: {
        api_key: '',
        api_secret: '',
        form_id_trustee_info: '',
        form_id_trustee_reminder: '',
        tag_id: ''
    }
}

export const initialAgreementInfo = {
    plan: '',
    agreement_first_signature: '',
    signature_1_date: '',
    agreement_second_signature: '',
    signature_2_date: '',
    agreement_third_signature: '',
    signature_3_date: '',
    is_acknowledgment_send: false,
    payment_plan: '',
    is_agree_and_signing: false,
    agree_and_signing_date: '',
    acknowledgment_email_address: '',
    acknowledgment_phone_number: '',
    attorney_fee_first_price: '',
    is_second_signature_show: true,
    is_third_signature_show: true,
    attorney_fee_second_price: '',
    number_of_month_for_attorney_fee: '',
    is_mail_sent: false,
    acknowledgments: [],
    is_client_created: false,
    client_created_at: '',
    is_file_uploaded_to_lm: false,
    file_uploaded_at: ''
}

export const initialWealthAdvisoryAgreementInfo = {
    client_name: '',
    advisor_name: 'Rod Yancy',
    advisor_signature: 'public/signature_rod.png',
    first_client_name: '',
    is_second_signature_show: false,
    second_client_name: '',
    date_of_contract_signing: '',
    is_agree_and_signing: false,
    acknowledgment_email_address: '',
    acknowledgment_phone_number: '',
    is_acknowledgment_send: false,
    is_mail_sent: false
}

export const initialSingleVision = {
    _id: '',
    modified_by: '',
    added_by: '',
    client_name: '',
    created_at_ipad: '',
    createdAt: '',
    updatedAt: '',
    notes: [],
    discount_level: 0,
    is_married: false,
    is_client_has_trust: false,
    estimated_assets_at_risk_to_lawsuits: '',
    estimated_assets_at_risk: '',
    agreement: initialAgreementInfo,
    estate_plan_problem_reviews: [],
    estate_protect_plan: [],
    client_rights_disclosure: {
        is_second_signature_show: false,
        is_third_signature_show: false,
        date_of_disclosure: '',
        acknowledgment_email_address: '',
        // acknowledgment_phone_number: string
        is_agree_and_signing: false,
        is_acknowledgment_send: false,
        is_mail_sent: false
    },
    basic_plan_image: '',
    income_graph_image: '',
    agreement_first_signature: '',
    agreement_second_signature: '',
    agreement_third_signature: '',
    crd_first_signature: '',
    contract_first_signature: '',
    wealth_advisory_agreement: initialWealthAdvisoryAgreementInfo,
    contract_second_signature: '',
    advisor_signature: '',
    crd_second_signature: '',
    assets: '',
    lawmatics_ep_qualified: '',
    ep_qualified_at: '',
    lawmatics_matter_id: '',
    lawmatics_contact_id: '',
    zephyr_probable_uid: '',
    recordings: []
}

export const initialTemplate = {
    _id: '',
    name: '',
    slug: '',
    is_all_states: false,
    states: [],
    content: [],
    display_order: 1,
    footer_text: '',
    should_create_document_for_both: false,
    enable_multiple_document_mode: false
}

export const initialValuesClientMatter = {
    _id: '',
    name: '',
    client: initialClientDetailsForMatters,
    modifier_name: '',
    added_by: '',
    note: '',
    type: '',
    created_at_ipad: '',
    createdAt: '',
    updatedAt: '',
    state_name: '',
    package: '',
    package_slug: '',
    documents: [],
    county: '',
    generate_date: '',
    is_updated_document: false,
    documents_with_content: [],
    signing_date: '',
    lawmatics_matter_id: '',
    lawmatics_contact_id: '',
    zephyr_probable_uid: '',
    automatic_mail_sent_at: '',
    is_name_and_fiduciary_mail_sent: false
}

export const initialStateSpecificDefault = {
    state: '',
    default_value: '',
    label: ''
}

export const initialConditionalOptions = {
    condition_id: '',
    condition_name: '',
    options_string: ''
}

export const initialButtons = {
    action_string: '',
    label: '',
    is_all_packages: true,
    packages: []
}

export const initialLabelDecoration = {
    scope: '',
    find: '',
    replace: ''
}

export const initialField = {
    _id: '',
    name: '',
    variable_name: '',
    type: '',
    need_grantor_as_option: false,
    need_spouse_as_option: true,
    contact_form_type: '',
    placeholder: '',
    default_value: '',
    description: '',
    is_dependable_default_answer: false,
    state_specific_default: [],
    is_conditional: false,
    conditions: {
        id: '',
        combinator: 'and',
        rules: []
    },
    options: [],
    options_string: '',
    is_multiple: false,
    is_sub_field: false,
    parent_field: '',
    is_hide_from_group_preview: false,
    is_hide_from_group_preview_if_unanswered: false,
    is_global: false,
    is_read_only: false,
    is_hidden: false,
    is_dependable_answer: false,
    dependable_answer_condition_id: '',
    default_answer_for_dependable: '',
    default_answer_for_dependable_state_wise: [],
    is_conditional_options: false,
    conditional_options: [],
    show_inline_description: false,
    is_add_button: false,
    text_transform: '',
    buttons: [],
    is_decorate_label: false,
    label_decorations: [],
    contact_list_type: '',
    use_for_non_trustees: false,
    should_reset: false,
    should_reset_following_fields_answers: [],
    is_answer_required: false
}

export const initialSinglePlural = {
    _id: '',
    singular: '',
    plural: ''
}

export const initialIpadLocalData = {
    _id: '',
    type: '',
    user_id: '',
    fetched_records: [],
    app_version: '',
    device_udid: '',
    device_os: '',
    device_time: '',
    device_name: ''
}

export const initialSingleStateSpecificHCTemp = {
    _id: '',
    state_id: '',
    template_id: ''
}

export const initialSinglePlan = {
    _id: '',
    name: '',
    plan_description: [],
    display_order: 1,
    price_for_single: {
        price: '',
        level_1_price: '',
        level_2_price: '',
        level_3_price: '',
        restatement_price: '',
        restatement_level_1_price: '',
        restatement_level_2_price: '',
        restatement_level_3_price: ''
    },
    price_for_married: {
        price: '',
        level_1_price: '',
        level_2_price: '',
        level_3_price: '',
        restatement_price: '',
        restatement_level_1_price: '',
        restatement_level_2_price: '',
        restatement_level_3_price: ''
    },
    edit_button: false,
    edit_button_action: '',
    is_visible: true,
    is_enabled: true
}

export const initialSinglePlanPricingDefaults = {
    _id: '',
    name: '',
    display_order: 1,
    price_for_single: {
        price: '',
        level_1_price: '',
        level_2_price: '',
        level_3_price: '',
        restatement_price: '',
        restatement_level_1_price: '',
        restatement_level_2_price: '',
        restatement_level_3_price: ''
    },
    price_for_married: {
        price: '',
        level_1_price: '',
        level_2_price: '',
        level_3_price: '',
        restatement_price: '',
        restatement_level_1_price: '',
        restatement_level_2_price: '',
        restatement_level_3_price: ''
    }
}

export const initialSinglePlanPricingTemplate = {
    _id: '',
    name: '',
    plans: []
}

export const defaultAllObj = { label: 'All', value: 'ALL' }

export const initialSingleEstateProtectPlan = {
    _id: '',
    plan_name: '',
    plan_description: '',
    display_order: 1
}

export const initialSingleMarriedText = {
    single_text: '',
    married_text: ''
}

export const initialEngagementTemplateDetails = {
    _id: '',
    name: '',
    left_text: [],
    right_text: [],
    email_text: {
        single_text: '',
        married_text: ''
    },
    is_default: false
}

export const initialSingleProblemQuestion = {
    _id: '',
    title: '',
    sub_title: '',
    options: [],
    display_order: 1
}


export const initialDescriptionInfo = {
    type: 'TEXT',
    text: '',
    image: ''
}

export const initialOptionInfo = {
    label: '',
    type: 'CHECKBOX',
    placeholder: '',
    display_order: 1,
    plan: [],
    is_active: true
}

export const initialErrorLogCronData = {
    _id: '',
    user_id: '',
    end_point: '',
    request_data: [],
    response_data: [],
    build_version: ''
}

export const initialEmailLogCronData = {
    _id: '',
    user_id: '',
    to: [],
    from: '',
    cc: [],
    bcc: [],
    subject: '',
    sent_status: false,
    error: {},
    type: '',
    createdAt: '',
    body: '',
    is_automatic_email: false,
    lawmatics_note_added: false
}

export const initialDeedFiling = {
    _id: '',
    createdAt: '',
    updatedAt: '',
    matter_name: '',
    client_id: '',
    signing_date: '',
    filing: '',
    filed: '',
    is_rejected: false,
    is_refiled: false,
    is_filing_completed: false,
    rejected_notes: '',
    refiled_notes: '',
    filing_by: '',
    filing_date: '',
    filed_by: '',
    filed_date: '',
    rejected_by: '',
    rejected_date: '',
    refiled_by: '',
    refiled_date: '',
    filing_completed_by: '',
    filing_completed_date: ''
}

export const initialDeeds = {
    _id: '',
    createdAt: '',
    updatedAt: '',
    matter_name: '',
    state_of_property: '',
    county_of_property: '',
    street_number: '',
    grantee: '',
    deed_notes: '',
    is_completed: false,
    completed_date: '',
    completed_by: '',
    matter_id: '',
    document_id: '',
    client_id: '',
    signing_date: '',
    uuid:''
}

export const initialPackage = {
    _id: '',
    name: '',
    templates: [],
    display_order: 0
}

export const initialMatter = {
    _id: '',
    name: '',
    note: '',
    createdAt: '',
    updatedAt: '',
    client_id: '',
    is_completed: false,
    completed_date: '',
    completed_by: '',
    note_added_by: [],
    modifier_name: '',
    signing_date: '',
    additional_info_completed_by: '',
    additional_info_completed_at: '',
}

export const initialNeedInfo = {
    _id: '',
    name: '',
    note: '',
    updatedAt: '',
    client_id: '',
    should_we_need_additional_info: false,
    note_added_by: [],
    modifier_name: '',
    signing_date: ''
}

export const initialCommonParagraph = {
    _id: '',
    name: '',
    variable_name: '',
    description: '',
    state_specific_values: [],
    value: '',
}

export const initialValueLabel = {
    value: '',
    label: ''
}

export const initialClientDraftAdjustment = {
    _id: '',
    note: '',
    title: ''
}

export const initialSingleAddress = {
    _id: '',
    title: '',
    street: '',
    city: '',
    county: '',
    state: '',
    state_id: '',
    zip_code: '',
    phone_number: '',
    updatedAt: ''
}

export const initialAppUser = {
    _id: '',
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    status: '',
    account_code: '',
    attorney_code: '',
    user_specific_price_template_id: '',
    engagement_template_id: '',
    user_associated_tags: [],
    default_vision_recording_status: true,
    address_id: '',
    default_state_id: '',
    default_county: ''
}

export const initialPackagePlanData = {
    _id: '',
    package_id: '',
    package_name: '',
    plan_id: '',
    plan_name: ''
}

export const initialTemplateSpecificPDFStyle = {
    _id: '',
    title: '',
    is_all_state: true,
    states: [],
    templates: [],
    css: '',
    is_active: true,
    weight: 1
}

export const debounceTime = 1000
