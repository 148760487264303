import { ExclamationTriangleFill } from 'react-bootstrap-icons'
import { dateFormatWithTime } from '../commonComponent/commonFuction'
import { Cell } from '../table/TableCellText.d'
import React, { useState } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import FormModal from '../commonComponent/FormModal'
import classNames from 'classnames'
import { englishStrings } from '../lawmatics/EnglishString'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import TimeAgo from 'react-timeago'
import moment from 'moment'
const scrollableBodyStyle = 'modal-body resource'

export const TableCellMatterName: React.FC<Cell> = (props) => {
    const { client_id, matter_id, matter_name } = props.data
    return <Link to={`/clients/${client_id}/matters/${matter_id}`} >{matter_name}</Link>
}

export const TableCellCheckboxWorking: React.FC<Cell> = (props) => {
    const { _id = '', is_working = false, matter_id, check_working_lawmatics_error, title, working_notes = '', working_by = '', working_date = '' } = props.data

    const value = _id
    const defaultValue = is_working
    const matterId = matter_id
    const error = check_working_lawmatics_error
    const defaultNote = working_notes
    const workingBy = working_by
    const workingAt = working_date

    const { register, handleSubmit, errors } = useForm()
    const [showNoteModal, setShowNoteModal] = useState(false)

    const onChangeValue = () => {
        props.data.clientDraftAdjustmentStore.markWorking(value, !defaultValue, matterId, defaultNote, false)
    }

    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }

    const onSaveNote = (data: any) => {
        let isNoteChanged = false
        if (data.note !== defaultNote) {
            isNoteChanged = true
        }

        if (data.note != null) {
            props.data.clientDraftAdjustmentStore.markWorking(value, defaultValue, matterId, data.note, isNoteChanged)
        }
        openCloseNoteModal()
    }

    return <>
        {showNoteModal && <>
            <FormModal show={showNoteModal} modalTitle={`Edit Working Note: ${title}`}  openCloseModal={openCloseNoteModal} modalDialogClass='modal-dialog-centered modal-lg' includeHeader={true}>
                <form onSubmit={handleSubmit(onSaveNote)}>
                    <fieldset>
                        <div className={scrollableBodyStyle}>
                            <div className='form-group'>
                                <textarea
                                    ref={register}
                                    name='note'
                                    defaultValue={defaultNote}
                                    style={{height:'300px'}}
                                    className={classNames('form-control', { 'is-invalid': errors.note })} />
                                {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                            <button type="submit" className="btn btn-outline-primary">Update</button>
                        </div>
                    </fieldset>
                </form>
            </FormModal>
        </>}

        <input 
            type='checkbox' 
            className='align-middle' 
            key={value + 'working'} 
            checked={defaultValue} 
            onChange={onChangeValue} 
        /> {!_.isEmpty(error) && <ExclamationTriangleFill className='text-warning' /> }<br/>

        {(defaultValue || defaultNote !== '') && <>
            <Link to='#' className={classNames({ 'text-secondary': (defaultNote === '') }) } onClick={() => setShowNoteModal(!showNoteModal)}>Notes</Link><br/>
        </>}
        <div className='custom-small-font'>
            {workingBy}<br />
            {dateFormatWithTime(workingAt)}
        </div>
    </>
}

export const TableCellCheckboxPending: React.FC<Cell> = (props) => {
    const { _id = '', is_pending = false, matter_id, check_pending_lawmatics_error, pending_notes = '', title, pending_by = '', pending_date = '' } = props.data

    const value = _id
    const defaultValue = is_pending
    const matterId = matter_id
    const error = check_pending_lawmatics_error
    const defaultNote = pending_notes
    const pendingBy = pending_by
    const pendingAt = pending_date

    const { register, handleSubmit, errors } = useForm()
    const [showNoteModal, setShowNoteModal] = useState(false)

    const onChangeValue = () => {
        props.data.clientDraftAdjustmentStore.markPending(value, !defaultValue, matterId, defaultNote, false)
    }

    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }

    const onSaveNote = (data: any) => {
        let isNoteChanged = false
        if (data.note !== defaultNote) {
            isNoteChanged = true
        }

        if (data.note != null) {
            props.data.clientDraftAdjustmentStore.markPending(value, defaultValue, matterId, data.note, isNoteChanged)
        }
        openCloseNoteModal()
    }

    return <>
        {showNoteModal && <>
            <FormModal show={showNoteModal} modalTitle={`Edit Pending Note: ${title}`}  openCloseModal={openCloseNoteModal} modalDialogClass='modal-dialog-centered modal-lg' includeHeader={true}>
                <form onSubmit={handleSubmit(onSaveNote)}>
                    <fieldset>
                        <div className={scrollableBodyStyle}>
                            <div className='form-group'>
                                <textarea
                                    ref={register}
                                    name='note'
                                    defaultValue={defaultNote}
                                    style={{height:'300px'}}
                                    className={classNames('form-control', { 'is-invalid': errors.note })} />
                                {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                            <button type="submit" className="btn btn-outline-primary">Update</button>
                        </div>
                    </fieldset>
                </form>
            </FormModal>
        </>}

        <input 
            type='checkbox' 
            className='align-middle' 
            key={value + 'pending'} 
            checked={defaultValue} 
            onChange={onChangeValue} 
        /> {!_.isEmpty(error) && <ExclamationTriangleFill className='text-warning' /> }
        <br />

        {(defaultValue || defaultNote !== '') && <>
            <Link to='#' className={classNames({ 'text-secondary': (defaultNote === '') }) } onClick={() => setShowNoteModal(!showNoteModal)}>Notes</Link><br/>
        </>}

        <div className='custom-small-font'>
            {pendingBy}<br />
            {dateFormatWithTime(pendingAt)}
        </div>
    </>
}

export const TableCellCheckboxDrafted: React.FC<Cell> = (props) => {
    const { _id = '', is_drafted = false, matter_id, check_drafted_lawmatics_error, drafted_notes = '', title, drafted_by = '', drafted_date = '' } = props.data
    
    const value = _id
    const defaultValue = is_drafted
    const matterId = matter_id
    const error = check_drafted_lawmatics_error
    const defaultNote = drafted_notes
    const draftedBy = drafted_by
    const draftedAt = drafted_date

    const { register, handleSubmit, errors } = useForm()
    const [showNoteModal, setShowNoteModal] = useState(false)

    const onChangeValue = () => {
        props.data.clientDraftAdjustmentStore.markDrafted(value, !defaultValue, matterId, defaultNote, false)
    }

    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }
    
    const onSaveNote = (data: any) => {
        let isNoteChanged = false
        if (data.note !== defaultNote) {
            isNoteChanged = true
        }

        if (data.note != null) {
            props.data.clientDraftAdjustmentStore.markDrafted(value, defaultValue, matterId, data.note, isNoteChanged)
        }
        openCloseNoteModal()
    }

    return <>
        {showNoteModal && <>
            <FormModal show={showNoteModal} modalTitle={`Edit Drafted Note: ${title}`}  openCloseModal={openCloseNoteModal} modalDialogClass='modal-dialog-centered modal-lg' includeHeader={true}>
                <form onSubmit={handleSubmit(onSaveNote)}>
                    <fieldset>
                        <div className={scrollableBodyStyle}>
                            <div className='form-group'>
                                <textarea
                                    ref={register}
                                    name='note'
                                    defaultValue={defaultNote}
                                    style={{height:'300px'}}
                                    className={classNames('form-control', { 'is-invalid': errors.note })} />
                                {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                            <button type="submit" className="btn btn-outline-primary">Update</button>
                        </div>
                    </fieldset>
                </form>
            </FormModal>
        </>}

        <input 
            type='checkbox' 
            className='align-middle' 
            key={value + 'drafted'} 
            checked={defaultValue} 
            onChange={onChangeValue} 
        /> {!_.isEmpty(error) && <ExclamationTriangleFill className='text-warning' /> }
        <br />
        {(defaultValue || defaultNote !== '') && <>
            <Link to='#' className={classNames({ 'text-secondary': (defaultNote === '') }) } onClick={() => setShowNoteModal(!showNoteModal)}>Notes</Link><br/>
        </>}

        <div className='custom-small-font'>
            {draftedBy}<br/>
            {dateFormatWithTime(draftedAt)}
        </div>
    </>
}

export const TableCellCheckboxReview: React.FC<Cell> = (props) => {
    const { _id = '', is_review = false, matter_id, check_review_lawmatics_error, review_notes = '', title, review_by = '', review_date = '' } = props.data

    const value = _id
    const defaultValue = is_review
    const matterId = matter_id
    const error = check_review_lawmatics_error
    const defaultNote = review_notes
    const reviewBy = review_by
    const reviewAt = review_date

    const { register, handleSubmit, errors } = useForm()
    const [showNoteModal, setShowNoteModal] = useState(false)

    const onChangeValue = () => {
        props.data.clientDraftAdjustmentStore.markReview(value, !defaultValue, matterId, defaultNote, false)
    }

    const openCloseNoteModal = () => {
        setShowNoteModal(!showNoteModal)
    }
    
    const onSaveNote = (data: any) => {
        let isNoteChanged = false
        if (data.note !== defaultNote) {
            isNoteChanged = true
        }
        if (data.note != null) {
            props.data.clientDraftAdjustmentStore.markReview(value, defaultValue, matterId, data.note, isNoteChanged)
        }
        openCloseNoteModal()
    }

    return <>
        {showNoteModal && <>
            <FormModal show={showNoteModal} modalTitle={`Edit Reviewed Note: ${title}`}  openCloseModal={openCloseNoteModal} modalDialogClass='modal-dialog-centered modal-lg' includeHeader={true}>
                <form onSubmit={handleSubmit(onSaveNote)}>
                    <fieldset>
                        <div className={scrollableBodyStyle}>
                            <div className='form-group'>
                                <textarea
                                    ref={register}
                                    name='note'
                                    defaultValue={defaultNote}
                                    style={{height:'300px'}}
                                    className={classNames('form-control', { 'is-invalid': errors.note })} />
                                {errors.note && (<div className='invalid-feedback'>{errors.note.message}</div>)}
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" onClick={openCloseNoteModal}>Cancel</button>
                            <button type="submit" className="btn btn-outline-primary">Update</button>
                        </div>
                    </fieldset>
                </form>
            </FormModal>
        </>}

        <input 
            type='checkbox' 
            className='align-middle' 
            key={value + 'review'} 
            checked={defaultValue} 
            onChange={onChangeValue} 
        /> {!_.isEmpty(error) && <ExclamationTriangleFill className='text-warning' /> }
        <br />

        {(defaultValue || defaultNote !== '') && <>
            <Link to='#' className={classNames({ 'text-secondary': (defaultNote === '') }) } onClick={() => setShowNoteModal(!showNoteModal)}>Notes</Link><br/>
        </>}
        <div className='custom-small-font'>
            {reviewBy}<br/>
            {dateFormatWithTime(reviewAt)}
        </div>
    </>
}

export const TableCellCheckboxCompleted: React.FC<Cell> = (props) => {
    const value = props.data._id || ''
    const defaultValue = props.data.is_completed || false
    const matterId = props.data.matter_id
    const error = props.data.check_completed_lawmatics_error
    const completedBy = props.data.completed_by || ''
    const completedAt = props.data.completed_date || ''

    const onChange = () => {
        props.data.clientDraftAdjustmentStore.markCompleted(value, !defaultValue, matterId)
    }

    return (<>
        <input type='checkbox' className='align-middle' key={value + 'complete'} checked={defaultValue} onChange={onChange} /> {!_.isEmpty(error) && <ExclamationTriangleFill className='text-warning' /> }<br/>
        <div className='custom-small-font'>
            {completedBy}<br />
            {dateFormatWithTime(completedAt)}
        </div>
    </>)
}

export const TableCellCreatedDetails: React.FC<Cell> = (props) => {
    const { created_by = '', createdAt = '',  } = props.data
    const createdBy = created_by
    const formatter = buildFormatter(englishStrings)
    const dateWithTime = moment(createdAt).utc().format('MM/DD/YYYY HH:mm:ss')

    return <>
        {createdBy}<br/>
        {dateWithTime}<br/>
        <TimeAgo date={createdAt} formatter={formatter}></TimeAgo>
    </>
}