import React from 'react'
import _ from 'lodash'
import TableCellText from '../table/TableCellText'
import { TableLoader } from '../commonComponent/Loader'
import { Link, useHistory } from 'react-router-dom'
import uniqueId from 'react-uuid'


interface Cell {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTableAction?: (action: string, value: any, data: any) => void
}

interface Column {
  name: string,
  title: string,
  class?: string,
  component?: React.FC<Cell>,
  sort?: boolean
  ref?: string
}


interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<any>
  unique_key: string
  columnAction: Array<Column>
  actionPerformedBy: Array<Column>
  actionPerformedDate: Array<Column>
  actionPerformedNote: Array<Column>
  isLoading?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTableAction?: (action: string, value: any, data: any) => void
}

interface MatterInfo {
    title?: string
    data: any
}

const MatterName: React.FC<MatterInfo> = (props) => {
    const { client_id, matter_id, matter_name, document_id, uuid, isHTMLDocumentAvailable } = props.data
    const history = useHistory()
    const matterNameAndLink: Array<JSX.Element> = []
    matterNameAndLink.push(<Link to={`/clients/${client_id}/matters/${matter_id}`} key={uniqueId()}>{matter_name}</Link>)

    !_.isUndefined(document_id) && matterNameAndLink.push(<React.Fragment key={uniqueId()} >
        <br/>
        <Link key={uniqueId()} to="#" className='mr-2' onClick={async () => {
            const htmlId = await isHTMLDocumentAvailable(document_id, matter_id, uuid, 'false')
            history.push(`/clients/${client_id}/matters/${matter_id}/HTML-document/${document_id}/false/${uuid}/${htmlId}`)
        }}>Edit HTML</Link>
    </React.Fragment>)   

    return (
        <>{matterNameAndLink}</>
    )
}

const Card: React.FC<Props> = (props) => {
    const onTableAction = props.onTableAction
    const { isLoading } = props

    const firstUpdate = React.useRef(true)
    React.useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false
            return
        }
    }, [])

    const columnAction = props.columnAction || []
    const actionPerformedBy = props.actionPerformedBy || []
    const actionPerformedDate = props.actionPerformedDate || []
    const actionPerformedNote = props.actionPerformedNote || []
    const data = props.data || []

    if (data.length == 0) {
        return <div className='card'><div className='responsive d-flex justify-content-center mb-3 mt-3'>No records found</div></div>
    }

    return (
        <div>
            {isLoading && <TableLoader isLoading={isLoading} /> }
            {data.map((row) => {
                const { signing_date, unique_key } = row
                return <>
                    <div key={unique_key} className='card m-2 card-font'>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col'>
                                    <label className='mr-2 mb-0'>Matter:</label>
                                    <MatterName data={row} />
                                </div>
                                <div className='ml-auto date-wrapper'>
                                    <label className='mr-2 mb-0'>Signing Date:</label>
                                    <span>{signing_date}</span> 
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row mb-1'>
                                {columnAction.map((col) => {
                                    const value = _.get(row, col.name, '')
                                    const component = col.component
                                    const Component = component || TableCellText
                                    const RenderComponent = <Component value={value} data={row} onTableAction={onTableAction}></Component>
                                    return <>
                                        <div className='col'>
                                            <label>{col.title}</label>
                                            <span className='ml-1'>{RenderComponent}</span>
                                        </div>
                                    </>
                                })}
                            </div>
                            <div className='row'>
                                {actionPerformedBy.map((col, i) => {
                                    const value = _.get(row, col.name, '')
                                    const component = col.component
                                    const Component = component || TableCellText
                                    const RenderComponent = <Component value={value} data={row} onTableAction={onTableAction}></Component>
                                    if (value == ''){
                                        return <div className='col' key={i}></div>
                                    }
                                    return <>
                                        <div className='col'>
                                            <label className='mb-0'>{col.title}</label>
                                            <span className='ml-1'>{RenderComponent}</span>
                                        </div>
                                    </>
                                })}
                            </div>
                            <div className='row mb-1'>
                                {actionPerformedDate.map((col, i) => {
                                    const value = _.get(row, col.name, '')
                                    const component = col.component
                                    const Component = component || TableCellText
                                    const RenderComponent = <Component value={value} data={row} onTableAction={onTableAction}></Component>
                                    if (value == ''){
                                        return <div className='col' key={i}></div>
                                    }
                                    return <>
                                        <div className='col'>
                                            <label>{col.title}</label>
                                            <span className='ml-1'>{RenderComponent}</span>
                                        </div>
                                    </>
                                })}
                            </div>
                            
                            <div className='row mb-1'>
                                <div className='col'></div>
                                {actionPerformedNote.map((col, i) => {
                                    const value = _.get(row, col.name, '')
                                    const component = col.component
                                    const Component = component || TableCellText
                                    const RenderComponent = <Component value={value} data={row} onTableAction={onTableAction}></Component>
                                    if (value == ''){
                                        return <div className='col' key={i}></div>
                                    }
                                    return <>
                                        <div className='col'>
                                            <label className='mb-0'>{col.title}</label>
                                            <span className='ml-1'>{RenderComponent}</span>
                                        </div>
                                    </>
                                })}
                                <div className='col'></div>
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className='col-3'>
                                    <label className='mr-1 mb-0'>Grantee:</label>
                                    <span>{row.grantee}</span>
                                </div>
                                <div className='col-3'>
                                    <label className='mr-1 mb-0'>State:</label>
                                    <span>{row.state_of_property}</span>
                                </div>
                                <div className='col-3'>
                                    <label className='mr-1 mb-0'>County:</label>
                                    <span>{row.county_of_property}</span>
                                </div>
                                <div className='col-3 d-flex'>
                                    <label className='mr-1 mb-0'>Street:</label>
                                    <span>{row.street_number}</span>
                                </div>
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className='col'>
                                    <label className='mr-1 mb-0'>Deed Note:</label>
                                    <span>{row.deed_notes}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            })}
        </div>
    )
}

export default Card