import { action, observable } from 'mobx'
import RootStore from './Root'

import {
    getProbableDetail,
    getProbables
} from '../api/ProbableActions'

import {
    ProbableInformation,
    SortConfig,
    ValueLabel
} from './types.d'

import {
    initialTableDataWithPaginationDetails,
    initialProbableDetails,
    defaultAllObj
} from './InitialValues'

interface ProbableData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<ProbableInformation>
}


class ProbableStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public probableData: ProbableData
    @observable public searchItem: string
    @observable public searchByState: ValueLabel
    @observable public excludeTestProbables: boolean
    @observable public filterWithSource: ValueLabel
    @observable public page: number
    @observable public isLoadingForModal: boolean
    @observable public probableDetail: ProbableInformation
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.page = 1
        this.probableData = initialTableDataWithPaginationDetails
        this.probableDetail = initialProbableDetails
        this.resetFilter()
    }
  
    @action setIsOpen = (isOpened: boolean): void => {
        this.isPageOpened = isOpened
    }

    @action resetFilter = () => {
        this.excludeTestProbables = true
        this.searchItem = ''
        this.filterWithSource = defaultAllObj
        this.searchByState = { value: 'All', label: 'All' }
    }

    @action setSearchItemField = (value: string): void => {
        this.searchItem = value
    }


    //get the list of Probables with details
    fetchProbable = (page: number, searchItem: string, searchByState: ValueLabel, filterWithSource: ValueLabel, excludeTestProbables: boolean): void => {
        this.isPageOpened = true
        this.page = page
        this.searchItem = searchItem
        this.searchByState = searchByState
        this.filterWithSource = filterWithSource
        this.excludeTestProbables = excludeTestProbables
        
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem.trim(), search_by_state: this.searchByState.value, filter_with_source: this.filterWithSource.value, exclude_test_probables: this.excludeTestProbables }

        getProbables(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.probableData = response.data.data
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    sortProbableData = (sortConfig: SortConfig): void => {
        const sortType = (sortConfig.direction === 'asc') ? 1 : -1
        this.probableData.page_items = this.probableData.page_items.slice().sort((item1: ProbableInformation, item2: ProbableInformation) => sortType * item1[sortConfig.key].localeCompare(item2[sortConfig.key]))
    }

    setProbableDetail = (data: string): void => {
        this.isLoadingForModal = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getProbableDetail(data).then((response) => {
            this.isLoadingForModal = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.probableDetail = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default ProbableStore
